// Base URL //
 
export const iLevelBaseURL = "https://staging.ilevel.org";
console.log("AppVars.js - " + window.location.origin);
export const local = window.location.origin;;
export const iLevelApiProxiURL = "https://staging.api.readysafe.org/track";
 
//AWS Cogntio URLs
export const AWS_COGNITO_CONFIG = {
  Region: "us-east-1",
  UserPoolId: "us-east-1_njqxAIvPd",
  AppClientId: "1dk7ruo3u2n1f55k5f8t7rrb54",
  MandatorySignIn: false,
  AuthenticationFlowType: "AUTHORIZATION_CODE",
  Oauth: {
    Domain: "https://readysafe-staging-org.auth.us-east-1.amazoncognito.com", //cognito domain
    Scope: ["openid", "profile"], //cognito scopes
    RedirectSignIn: `${local}/#/dashboard`, //redirectURI
    RedirectSignOut: "https://alliancesafetycouncil.org", //cognito redirect upon signout
    ResponseType: "code", //'token' or 'code' - Note: no refresh token given when using code.
  },
};
 
// iLevel Links //
 
export const myProfileExtension = `${iLevelBaseURL}/SCUserProfile.aspx`;
export const classRegistration = `${iLevelBaseURL}/Registration/Online/SCRegistration.aspx`;
export const cancelRegistration = `${iLevelBaseURL}/ScRegiChangeDialog.aspx`;
export const individualTrainingHistory = `${iLevelBaseURL}/SCStudentHistory.aspx`;
export const receipts = `${iLevelBaseURL}/SCReport_Receipts.aspx`;
export const GoToAlliance = "https://www.alliancesafetycouncil.org";
 
// Utility //
 
export const minutesCacheIsValid = 10;
